import * as React from 'react';
import { useGetAllTermsAndConditions } from 'src/cms';
import { Layout } from 'src/components/layout/layout';
import { DocPage } from 'src/components/lib/docPage';

const TermsAndConditionsPage = () => {
  const [termsAndConditions] = useGetAllTermsAndConditions();

  return (
    <Layout>
      <DocPage title="Terms & Conditions" body={termsAndConditions.termsAndConditions} />
    </Layout>
  );
};
export default TermsAndConditionsPage;
